var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-5",attrs:{"fluid":""}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('base-material-card',{key:_vm.id,staticClass:"px-5 py-4 ml-0",attrs:{"color":"primary","icon":"mdi-toolbox-outline","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v(" "+_vm._s(_vm.id == null ? "Novo tipo de serviço" : "Editar tipo de serviço")+" ")])]},proxy:true}],null,true)},[_c('v-row',{staticClass:"mx-auto "},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('validation-provider',{attrs:{"name":"Código","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Código","prepend-icon":"mdi-numeric","readonly":_vm.id != null,"disabled":_vm.id != null,"value":_vm.tipoServico.codigo,"error-messages":errors},on:{"change":function($event){_vm.tipoServico.codigo = $event}}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"7"}},[_c('validation-provider',{attrs:{"name":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nome","prepend-icon":"mdi-text-box-outline","error-messages":errors},model:{value:(_vm.tipoServico.nome),callback:function ($$v) {_vm.$set(_vm.tipoServico, "nome", $$v)},expression:"tipoServico.nome"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mx-auto "},[_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('validation-provider',{attrs:{"name":"Categoria de Serviços","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.itemsSelectCategoriaServicos,"prepend-icon":"mdi-wrench","label":"Categoria de Serviços","error-messages":errors,"hide-details":""},model:{value:(_vm.tipoServico.categoriaId),callback:function ($$v) {_vm.$set(_vm.tipoServico, "categoriaId", $$v)},expression:"tipoServico.categoriaId"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mx-auto "},[_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('validation-provider',{attrs:{"name":"Descrição","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Descrição","prepend-icon":"mdi-text-long","error-messages":errors},model:{value:(_vm.tipoServico.descricao),callback:function ($$v) {_vm.$set(_vm.tipoServico, "descricao", $$v)},expression:"tipoServico.descricao"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mx-auto "},[_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('validation-provider',{attrs:{"name":"Artigo REN 1000","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Artigo REN 1000","prepend-icon":"mdi-folder-text-outline","error-messages":errors},model:{value:(_vm.tipoServico.artigoRen1000),callback:function ($$v) {_vm.$set(_vm.tipoServico, "artigoRen1000", $$v)},expression:"tipoServico.artigoRen1000"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('validation-provider',{attrs:{"name":"Prazo Regulado","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Prazo Regulado","type":"number","min":"0","prepend-icon":"mdi-clipboard-text-clock","error-messages":errors},model:{value:(_vm.tipoServico.prazo),callback:function ($$v) {_vm.$set(_vm.tipoServico, "prazo", $$v)},expression:"tipoServico.prazo"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('validation-provider',{attrs:{"name":"Unidade do prazo","rules":_vm.tipoServico.prazo ? 'required' : '',"immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.prazoReguladoUnidades,"prepend-icon":"mdi-clipboard-text-clock","label":"Unidade do prazo","error-messages":errors},model:{value:(_vm.tipoServico.prazoUnidade),callback:function ($$v) {_vm.$set(_vm.tipoServico, "prazoUnidade", $$v)},expression:"tipoServico.prazoUnidade"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mx-auto"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticStyle:{"float":"right"},attrs:{"color":"success","min-width":"100","loading":_vm.loading,"disabled":invalid},on:{"click":_vm.saveData}},[_vm._v(" Salvar ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }