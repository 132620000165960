<template>
  <v-container fluid class="pt-5">
    <validation-observer v-slot="{ invalid }">
      <base-material-card
        color="primary"
        icon="mdi-toolbox-outline"
        inline
        class="px-5 py-4 ml-0"
        :key="id"
      >
        <template v-slot:after-heading>
          <div class="display-2 font-weight-light">
            {{ id == null ? "Novo tipo de serviço" : "Editar tipo de serviço" }}
          </div>
        </template>

        <v-row class="mx-auto ">
          <v-col cols="12" sm="3">
            <validation-provider
              v-slot="{ errors }"
              name="Código"
              rules="required|numeric"
            >
              <v-text-field
                label="Código"
                prepend-icon="mdi-numeric"
                :readonly="id != null"
                :disabled="id != null"
                :value="tipoServico.codigo"
                @change="tipoServico.codigo = $event"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>

          <v-col cols="12" sm="7">
            <validation-provider
              v-slot="{ errors }"
              name="Nome"
              rules="required"
            >
              <v-text-field
                label="Nome"
                prepend-icon="mdi-text-box-outline"
                v-model="tipoServico.nome"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row class="mx-auto ">
          <v-col cols="12" sm="10">
            <validation-provider
              v-slot="{ errors }"
              name="Categoria de Serviços"
              rules="required"
            >
              <v-autocomplete
                :items="itemsSelectCategoriaServicos"
                v-model="tipoServico.categoriaId"
                prepend-icon="mdi-wrench"
                label="Categoria de Serviços"
                :error-messages="errors"
                hide-details
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row class="mx-auto ">
          <v-col cols="12" sm="10">
            <validation-provider
              v-slot="{ errors }"
              name="Descrição"
              rules="required"
            >
              <v-text-field
                label="Descrição"
                prepend-icon="mdi-text-long"
                v-model="tipoServico.descricao"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row class="mx-auto ">
          <v-col cols="12" sm="5">
            <validation-provider
              v-slot="{ errors }"
              name="Artigo REN 1000"
              rules="required"
            >
              <v-text-field
                label="Artigo REN 1000"
                prepend-icon="mdi-folder-text-outline"
                v-model="tipoServico.artigoRen1000"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>

          <v-col cols="12" sm="2">
            <validation-provider
              v-slot="{ errors }"
              name="Prazo Regulado"
              rules="numeric"
            >
              <v-text-field
                label="Prazo Regulado"
                type="number"
                min="0"
                prepend-icon="mdi-clipboard-text-clock"
                v-model="tipoServico.prazo"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>

          <v-col cols="12" sm="3">
            <validation-provider
              v-slot="{ errors }"
              name="Unidade do prazo"
              :rules="tipoServico.prazo ? 'required' : ''"
              immediate
            >
              <v-autocomplete
                :items="prazoReguladoUnidades"
                v-model="tipoServico.prazoUnidade"
                prepend-icon="mdi-clipboard-text-clock"
                label="Unidade do prazo"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row class="mx-auto">
          <v-col cols="12">
            <v-btn
              color="success"
              min-width="100"
              style="float: right;"
              :loading="loading"
              @click="saveData"
              :disabled="invalid"
            >
              Salvar
            </v-btn>
          </v-col>
        </v-row>
      </base-material-card>
    </validation-observer>
  </v-container>
</template>

<script>
  import TiposServicosService from "../../../services/TiposServicosService";
  import IndicadoresServicosV2Service from "../../../services/IndicadoresServicosV2Service";

  export default {
    name: "TiposServicosEdit",
    data: () => ({
      loading: false,
      tipoServico: {
        codigo: null,
        nome: "",
        descricao: "",
        artigoRen1000: "",
        prazo: null,
        prazoUnidade: null,
        categoriaId: null,
      },
      categoriasServicos: [],
      prazoReguladoUnidades: ["dias úteis", "dias", "horas"],
    }),
    computed: {
      id() {
        return this.$route.params.id ?? null;
      },
      itemsSelectCategoriaServicos() {
        return this.categoriasServicos.map((categoria) => {
          return { text: categoria.nome, value: categoria.id };
        });
      },
    },
    mounted() {
      this.getCategoriasServicos();
      this.getTipoServico();
    },

    methods: {
      getTipoServico() {
        if (!this.id) return;

        this.loading = true;

        return TiposServicosService.get(this.id)
          .then((t) => {
            const [prazo, ...unidadeTokens] = t.detalhe_servico.prazo_regulado
              ? t.detalhe_servico.prazo_regulado_raw.split(" ")
              : [null, null];
            const prazoUnidade = unidadeTokens.join(" ");

            this.tipoServico = {
              codigo: t.codigo,
              nome: t.servico,
              descricao: t.detalhe_servico.descricao,
              artigoRen1000: t.detalhe_servico.artigo_ren_1000,
              prazo,
              prazoUnidade,
              categoriaId: t.categorias ? t.categorias[0].id : null,
            };
          })
          .catch((err) => {
            console.error(err);
            this.$toast.error("Ocorreu um erro ao recuperar os dados.", "", {
              position: "topRight",
            });
          })
          .finally(() => (this.loading = false));
      },
      getCategoriasServicos() {
        return IndicadoresServicosV2Service.getCategoriasServicos()
          .then((res) => {
            this.categoriasServicos = res;
          })
          .catch((err) => {
            this.$toast.error("Erro ao recuperar categorias de serviços.", "", {
              position: "topRight",
            });
            throw err;
          });
      },
      saveData() {
        this.loading = true;

        if (this.id) {
          TiposServicosService.atualizar(this.tipoServico)
            .then((res) => {
              this.$toast.success(
                "Tipo de serviço atualizado com sucesso.",
                "",
                { position: "topRight" }
              );
              this.$router.push(`/administracao/tipos-servicos`);
            })
            .catch((err) => {
              console.error(err);
              this.$toast.error(
                "Ocorreu um erro ao atualizar o tipo de serviço.",
                "",
                { position: "topRight" }
              );
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          TiposServicosService.criar(this.tipoServico)
            .then((res) => {
              this.$toast.success("Tipo de serviço criado com sucesso.", "", {
                position: "topRight",
              });
              this.$router.push(`/administracao/tipos-servicos`);
            })
            .catch((err) => {
              console.error(err);
              this.$toast.error(
                "Ocorreu um erro ao criar o tipo de serviço.",
                "",
                { position: "topRight" }
              );
            })
            .finally(() => {
              this.loading = false;
            });
        }
      },
    },
  };
</script>

<style></style>
