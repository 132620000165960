import APIService from "./APIService";

export default {
    async getAll() {
        const res = await APIService.apiCall().get('tipos-servicos/get-tipos-servicos');
        return res.data;
    },
    async get(id) {
        const res = await APIService.apiCall().get(`tipos-servicos/get-tipo-servico/${id}`);
        return res.data;
    },
    async criar(tipoServico) {
        const res = await APIService.apiCall().post(`tipos-servicos/criar-tipo-servico`, tipoServico);
        return res.data;
    },
    async atualizar(tipoServico) {
        const res = await APIService.apiCall().put(`tipos-servicos/atualizar-tipo-servico`, tipoServico);
        return res.data;
    }
};